import axios from 'axios'
import { SearchProvider } from '@faststore/sdk'
import React from 'react'
import { useSearchParams } from 'src/hooks/useSearchParams'
import { mark } from 'src/sdk/tests/mark'
import { useSession } from 'src/sdk/session'
import { ITEMS_PER_PAGE, ITEMS_PER_PAGE_TABLET } from 'src/constants'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { applySearchState } from 'src/sdk/search/state'
import { graphql } from 'gatsby'
import StoreGallery from 'src/components/sections/PlpSellerStore/StoreGallery'
import type {
  SecondServerCollectionPageQueryQuery,
  SecondCollectionPageQueryQuery,
  SecondCollectionPageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import type { PlpProps } from 'src/components/sections/PlpSellerStore/types'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'

import storeConfig from '../../../store.config'

type Props = PageProps<
  SecondCollectionPageQueryQuery,
  SecondCollectionPageQueryQueryVariables,
  unknown,
  SecondServerCollectionPageQueryQuery | null
> & { serverData: PlpProps }

function Page(props: Props) {
  const { locale } = useSession()
  const searchParams = useSearchParams(props)
  const title = 'Resultados de busca | Vivara'
  const description = 'Tornando toda história única e especial'
  const { width } = useWindowDimensions()

  const ITEM_PER_PAGE_VERIFIED = width
    ? width < 768
      ? ITEMS_PER_PAGE
      : width <= 1279
      ? ITEMS_PER_PAGE_TABLET
      : ITEMS_PER_PAGE
    : ITEMS_PER_PAGE

  const { serverData } = props

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEM_PER_PAGE_VERIFIED}
      {...searchParams}
    >
      <GatsbySeoCustom
        language={locale}
        title={title}
        description={description}
        noindex
        nofollow
      />

      <StoreGallery plpProps={serverData} />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query sellerPlp {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const { account } = storeConfig
  const THIRTY_MINUTES_CACHE = `s-maxage=1800, stale-while-revalidate`

  const { data: plpData } = await axios.get(
    `https://${account}.myvtex.com/_v/api/intelligent-search/product_search/private-seller/${slug}?hideUnavailableItems=true&regionalizationBehavior=exclusive&count=16`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  )

  if (plpData.products.length === 0) {
    const params = new URLSearchParams({
      from: encodeURIComponent(`/loja/${slug}`),
    })

    const urlRedirect = `/404/?${params.toString()}}`

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
        location: urlRedirect,
      },
    }
  }

  return {
    status: 200,
    props: { ...(plpData ?? {}), slug },
    headers: {
      'cache-control': THIRTY_MINUTES_CACHE,
    },
  }
}

Page.displayName = 'Page'
export default mark(Page)
